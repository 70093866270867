body {
  margin: auto;
  /* color: #fff !important; */
  background-color: #333 !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #fff !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#root-container {
  margin: auto;
  max-width: 500px;
}

.btn-focus {
  background-color: black !important;
  border: #ffc107 1px solid !important;
  color: #ffc107 !important;
  font-size: 20pt !important;
}

/* .MuiTab-wrapper {
  color: #888 !important;
}

.MuiButtonBase-root {
  color: #888 !important;
} */

/* .MuiTab-textColorSecondary {
  color: #333;
} */

/* .am-list-item .am-list-line .am-list-content {
  flex: 1 4 !important;
  width: 20vw !important;
} */
